import { FC } from 'react';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import { Select } from 'components/basic';
import { SelectFilterProps } from './types';

const SelectFilter: FC<SelectFilterProps> = ({
  name,
  value = '',
  loading,
  placeholder,
  data,
  labelKey,
  onChange,
  allowClear = true,
  valueKey,
  ...restProps
}) => {
  const key = valueKey ? valueKey : 'id';
  const label = labelKey ? labelKey : 'name';

  return (
    <>
      <Select
        value={value}
        style={{ width: '100%' }}
        loading={loading}
        showArrow
        data-test={createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Dropdown,
          prefix: name
        })}
        allowClear={allowClear}
        filterOption={(input, option) =>
          (option?.children as unknown as string)
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        placeholder={placeholder}
        onChange={value => {
          onChange({
            name,
            label,
            value: value === undefined ? null : value //empty value should be null,
          });
        }}
        {...restProps}
      >
        {data.map((item: any) => {
          return (
            <Select.Option
              key={item[key]}
              value={item[key]}
              data-test={createDataTestAttribute({
                dataTestAttribute: DataTestAttributes.Option,
                prefix: item[key] || 'all'
              })}
            >
              {item[label]}
            </Select.Option>
          );
        })}
      </Select>
    </>
  );
};

export default SelectFilter;
